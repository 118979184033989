import axios from "axios";
import config from "../../config";

export function getCounterDocument() {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/summary`,
      method: "get",
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getListDocument(params, query) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/${query}`,
      method: "get",
      params: params,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDetailDocument(params) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/detail`,
      method: "get",
      params: params,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPdfFile(params) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/load`,
      method: "get",
      params: params,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postProcess(data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/revise`,
      method: "post",
      data: data,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postSign(data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/sign`,
      method: "post",
      data: data,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postMandiri(data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/mandiri`,
      method: "post",
      data: data,
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function verifyDocument(params) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${config.baseUrl}/verification`,
      method: "get",
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
